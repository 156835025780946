<template>
	<div>
		<v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pt-8 pb-0">
                <v-alert dense outlined type="info" class="mb-0">This is the list of promoters that have been deployed under this brand. It can be used as a reference for subsequent campaigns under this brand.</v-alert>
            </v-col>
			<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-sheet color="white" elevation="0" class="pa-0 py-4">
					<p class="text-h6 mb-4">Search</p>
					<v-row>
						<v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
							<v-text-field dense clearable hide-details label="Name" v-model="filters.name"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
							<v-text-field dense clearable hide-details label="NRIC" v-model="filters.nric"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
							<v-text-field dense clearable hide-details label="Mobile Number" v-model="filters.mobile_number"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" order="last">
							<v-btn small color="primary" class="mr-2 elevation-0" :ripple="false" @click='search'>
								<v-icon small left>mdi-magnify</v-icon> Search
							</v-btn>
							<v-btn small class="elevation-0" :ripple="false" @click='reset'>
								<v-icon small left>mdi-undo</v-icon> Reset
							</v-btn>
						</v-col>
					</v-row>
				</v-sheet>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="mx-2">
                <v-sheet color="white" elevation="0">
                    <v-data-table hide-default-footer class="elevation-0" loading-text="Loading... Please wait..." no-data-text="No data available yet" :loading="loading" :items-per-page="50" :headers="headers" :items="items">
                        <template v-slot:item='{ item, index }'>
                            <tr>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="index + 1"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.first_name"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.last_name"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.alias_name"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.mobile_number"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.created_on"></td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-sheet>
			</v-col>
		</v-row>
		<v-row v-if="!loading && pagination.total_pages > 1">
			<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
				<v-pagination circle :length="pagination.total_pages" :total-visible="7" v-model="filters.page" @input="get"></v-pagination>
			</v-col>
		</v-row>
	</div>
</template>


<script>

export default {
	name: 'ProjectPromoterList',
	data () {
		return {
			loading: false,
			filters: {
                project: this.$route.params.id,
				name: '',
				nric: '',
				mobile_number: '',
				page: 1
			},
			items: [],
			headers: [],
			pagination: {}
		}
	},
	mounted: function() {
		this.get()
	},
	methods: {
		get: function() {
			this.loading = true
			this.items = []
			this.$store.dispatch('epanel/project/getProjectPromoters', this.filters).then((response) => {
				this.items = response.data.items
				this.headers = response.data.headers
				this.pagination = response.data.pagination
				this.loading = false
			}).catch(() => {
				this.loading = false
			})
		},
		search: function() {
			this.loading = true
			this.filters.page = 1
			this.get()
		},
		reset: function() {
			this.filters = { project: this.$route.params.id, name: '', nric: '', mobile_number: '', page: 1}
		},
    }
}

</script>