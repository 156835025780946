<template>
    <v-dialog persistent max-width="764px" v-model="dialog">
        <v-card>
            <v-card-title class="pl-4 pr-4">
                <span class="text-h5">Delete Project Resource</span>
            </v-card-title>
            <v-card-text class="pl-4 pr-4">
                <v-row>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pb-0" v-if="errors && errors.message">
                        <v-alert dense outlined type="error" class="mb-0"><span v-text="errors.message"></span></v-alert>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <v-alert dense class="text-subtitle-1" type="warning">
                            <span>Are you sure that you want to delete this resource? Once you delete the resource, all relevant data will be deleted at the same time.</span>
                        </v-alert>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="text-right pr-6 pl-6">
                <v-spacer></v-spacer>
                <v-btn text @click="close">Cancel</v-btn>
                <v-btn text color="red" :loading="loading" @click="remove">Delete</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>

export default {
    name: 'ProjectResourceDeleteDialog',
    data() {
        return {
            dialog: false,
            loading: false,
            object: {
                id:  ''
            },
            errors: {}
        }
    },
    methods: {
        open: function() {
            this.dialog = true
        },
        close: function() {
            this.dialog = false
            this.loading = false
            this.object = { id: '' }
            this.errors = {}
        },
        updateObject: function(item) {
            this.object = item
        },
        remove: function() {
            this.loading = true

            this.$store.dispatch("epanel/project/deleteProjectResource", this.object).then((response) => {
                this.close()
                this.$emit('deleted', { message: response.message })
            }).catch((errors) => {
                this.errors = errors.data?errors.data.errors:errors
                this.loading = false
            })
        }
    }
}

</script>