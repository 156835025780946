<template>
	<div>
        <v-row v-if="!loading && !permissions.can_view">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <p class="text-h5 mb-4">Brand Info</p>
                <p class="text-title mb-0">You do not have permission to view brand info</p>
            </v-col>
        </v-row>
        <template v-if="permissions.can_view">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-sheet color="white" elevation="0" class="pa-0">
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <p class="text-h4 mb-0" v-text="object.name"></p>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="text-right">
                                <v-tooltip bottom><template v-slot:activator="{ on, attrs }"><v-btn icon v-bind="attrs" v-on="on" @click="back"><v-icon>mdi-arrow-left</v-icon></v-btn></template><span>Back</span></v-tooltip>
                                <v-tooltip bottom v-if="isEditable()"><template v-slot:activator="{ on, attrs }"><v-btn icon color="primary" v-bind="attrs" v-on="on" @click="openUpdateDialog"><v-icon>mdi-pencil</v-icon></v-btn></template><span>Edit</span></v-tooltip>
                                <v-tooltip bottom v-if="isArchiveable()"><template v-slot:activator="{ on, attrs }"><v-btn icon color="primary" v-bind="attrs" v-on="on" @click="openArchiveDialog('AR')"><v-icon>mdi-package-down</v-icon></v-btn></template><span>Archive</span></v-tooltip>
                                <v-tooltip bottom v-if="isActivatable()"><template v-slot:activator="{ on, attrs }"><v-btn icon color="primary" v-bind="attrs" v-on="on" @click="openArchiveDialog('AC')"><v-icon>mdi-package-up</v-icon></v-btn></template><span>Activate</span></v-tooltip>
                                <v-tooltip bottom v-if="isDeletable()"><template v-slot:activator="{ on, attrs }"><v-btn icon color="red" v-bind="attrs" v-on="on" @click="openDeleteDialog" ><v-icon>mdi-delete</v-icon></v-btn></template><span>Delete</span></v-tooltip>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <display-label label="Client" :text="object.client.text"></display-label>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <display-label label="Status" :text="object.status.text"></display-label>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <display-label label="Created On" :text="object.created_on"></display-label>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                                <display-label list label="Project Managers" v-if="object.managers.length > 0">
                                    <div slot="list">
                                        <v-chip small label color="primary" class="mr-2 mt-2" :key="index" v-for="(item, index) in object.managers"><span v-text="item.text"></span></v-chip>
                                    </div>
                                </display-label>
                                <display-label label="Project Managers" text="N.A." v-if="object.managers.length == 0"></display-label>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-row v-if="hasTab()">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-sheet color="white" elevation="0">
                        <v-tabs color="primary" :ripple="false" v-model="tab">
                            <v-tab v-if="permissions.can_view_campaigns">Campaign</v-tab>
                            <v-tab v-if="permissions.can_view_skus">SKU</v-tab>
                            <v-tab v-if="permissions.can_view_accounts">History of Development</v-tab>
                            <v-tab v-if="permissions.can_view_resources">Resource</v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab">
                            <v-tab-item v-if="permissions.can_view_campaigns">
                                <campaign-list :addable="!object.is_archived"></campaign-list>
                            </v-tab-item>
                            <v-tab-item v-if="permissions.can_view_skus">
                                <sku-list :addable="!object.is_archived"></sku-list>
                            </v-tab-item>
                            <v-tab-item v-if="permissions.can_view_accounts">
                                <promoter-list></promoter-list>
                            </v-tab-item>
                            <v-tab-item v-if="permissions.can_view_resources">
                                <resource-list :addable="!object.is_archived"></resource-list>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-sheet>
                </v-col>
            </v-row>
        </template>
		<update-dialog ref="updateDialog" :managers="managers" @updated="updateObject"></update-dialog>
		<archive-dialog ref="archiveDialog" @archived="updateObject"></archive-dialog>
		<delete-dialog ref="deleteDialog" @deleted="handleDeleted"></delete-dialog>
		<message-notifier ref="notifier"></message-notifier>
	</div>
</template>


<script>

import { delay } from '@/utils/helper'
import MessageNotifier from '../../../shared/MessageNotifier.vue'
import DisplayLabel from '../../../shared/DisplayLabel.vue'
import ProjectUpdateDialog from './ProjectUpdateDialog.vue'
import ProjectDeleteDialog from './ProjectDeleteDialog.vue'
import ProjectArchiveDialog from './ProjectArchiveDialog.vue'
import ProjectCampaignList from './campaign/ProjectCampaignList.vue'
import ProjectSKUList from './sku/ProjectSKUList.vue'
import ProjectPromoterList from './promoter/ProjectPromoterList.vue'
import ProjectResourceList from './resource/ProjectResourceList.vue'

export default {
	name: 'ProjectInfo',
	components: {
        messageNotifier: MessageNotifier,
        displayLabel: DisplayLabel,
        deleteDialog: ProjectDeleteDialog,
        updateDialog: ProjectUpdateDialog,
        archiveDialog: ProjectArchiveDialog,
        campaignList: ProjectCampaignList,
        skuList: ProjectSKUList,
        promoterList: ProjectPromoterList,
        resourceList: ProjectResourceList,
	},
	data () {
		return {
			loading: false,
			tab: null,
			object: {
                name: '',
                created_on: '',
                is_archived: false,
                client: {},
                status: {},
                managers: [],
			},
			permissions: {},
			managers: []
		}
	},
	computed: {
        updateDialog() {
            return this.$refs.updateDialog
        },
        deleteDialog() {
            return this.$refs.deleteDialog
        },
        archiveDialog() {
            return this.$refs.archiveDialog
        },
		notifier() {
            return this.$refs.notifier
        }
	},
	mounted: function() {
		this.get()
	},
	methods: {
        back: function(){
            this.$router.replace('/epanel/project/brand')
        },
		get: function() {
			this.loading = true
			this.items = []
			this.$store.dispatch('epanel/project/getProject', { id: this.$route.params.id, object: { type: 'info' }}).then((response) => {
                this.object = response.data.object
                this.managers = response.data.managers
                this.permissions = response.data.permissions
				this.loading = false
			}).catch(() => {
				this.loading = false
			})
		},
        openUpdateDialog: function() {
            if(this.isEditable()){
                this.updateDialog.updateObject({ id: this.object.id, client: this.object.client.text, name: this.object.name, managers: this.object.managers.map((e) => { return e.value }) })
                this.updateDialog.open()
            }
        },
        openDeleteDialog: function() {
            if(this.isDeletable()){
                this.deleteDialog.updateObject({ id: this.object.id })
                this.deleteDialog.open()
            }
        },
        openArchiveDialog: function(status) {
            if(this.isArchiveable() || this.isActivatable()){
                this.archiveDialog.updateObject({ id: this.object.id, status: status })
                this.archiveDialog.open()
            }
        },
		openNotifier: function(message) {
            this.notifier.updateText(message)
            this.notifier.open()
        },
        updateObject: function(item) {
            this.$set(this, 'object', item.object)
            this.openNotifier(item.message)
		},
		handleDeleted: function(item) {
            this.openNotifier(item.message)
            delay(this.back)
		},
        hasTab: function() {
            const permissions = ["can_view_campaigns", "can_view_skus", "can_view_accounts", "can_view_resources"]
            return permissions.filter((item) => this.permissions[item] === true).length > 0
        },
        isEditable: function() {
            return !this.object.is_archived && this.permissions.can_edit
        },
        isArchiveable: function() {
            return !this.object.is_archived && this.permissions.can_archive
        },
        isActivatable: function() {
            return this.object.is_archived && this.permissions.can_archive
        },
        isDeletable: function(){
            return !this.object.is_archived && this.permissions.can_delete
        }
	}
}

</script>