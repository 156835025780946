<template>
	<v-dialog persistent max-width="764px" ref="updateDialog" v-model="dialog">
		<v-form lazy-validation ref="form" v-model="valid">
			<v-card>
				<v-card-title class="pl-4 pr-4">
					<span class="text-h5">Update Brand SKU</span>
				</v-card-title>
				<v-card-text class="pl-4 pr-4">
					<v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="errors && errors.message">
                            <v-alert dense outlined type="error" class="mb-0"><span v-text="errors.message"></span></v-alert>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
							<v-text-field dense label="SKU Name*" :rules="rules.name" :error-messages="errors.name" v-model="object.name"></v-text-field>
						</v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-text-field dense label="Retail Price" :rules="rules.price" :error-messages="errors.price" v-model="object.price"></v-text-field>
                        </v-col>
						<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-file-input ref="file" label="PDF File" :rules="rules.file" show-size accept="image/png, image/jpg, image/jpeg" :error-messages="errors.file" @change="readFileData"></v-file-input>
                            <small class="red--text">Note: Only accepts PNG, JPG, JPEG file (Less than 1 MB). Recommended dimension is 200x200.</small>
                        </v-col>
					</v-row>
					<v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <small class="red--text">* Required field</small>
                        </v-col>
					</v-row>
				</v-card-text>
				<v-card-actions class="pl-4 pr-4">
					<v-spacer></v-spacer>
					<v-btn text @click="close">Close</v-btn>
					<v-btn text color="primary" :loading="loading" @click="save">Save Changes</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>


<script>

import { copyObject } from '@/utils/helper'

export default {
	name: 'ProjectSKUUpdateDialog',
	data() {
		return {
			dialog: false,
			valid: true,
			loading: false,
			object: {
                project: '',
                name: '',
                price: '',
                file: ''
			},
			rules: {
				name: [
					(value) => !!value || 'SKU Name is required',
					(value) => value && value.length >= 2 && value.length <= 100 || 'Name must be between 6 and 100 characters'
				],
                price: [
                    (value) => {
                        if(value && isNaN(value)){
                            return "Retail Price must be a numeric value"
                        }

                        return true
                    }
                ],
				file: [
                    (value) => {
                        if(value && Math.ceil(value.size / 1024) > 1024) {
                            return 'Image File must less than 1MB'
                        }

                        return true
                    }
                ]
			},
			errors: {}
		}
	},
	computed: {
		updateDialog() {
			return this.$refs.updateDialog
		},
		form() {
			return this.$refs.form
		}
	},
	methods: {
		open: function() {
			this.dialog = true
		},
		close: function() {
			this.dialog = false
			this.loading = false
			this.object = { project: '', name: '', price: '', file: '' }
			this.errors = {}
			this.form.resetValidation()
		},
		updateObject: function(item) {
            this.object = item
		},
		save: function() {
			if(this.form.validate()){
				this.loading = true
                const object = copyObject(this.object, ['id'])

                this.$store.dispatch('epanel/project/updateProjectSKU', { id: this.object.id, object: object }).then((response) => {
                    this.$emit('updated', { object: response.data.object, message: response.message })
                    this.close()
                }).catch((errors) => {
                    this.errors = errors.data?errors.data.errors:errors
                    this.loading = false
                })
			}
		},
		readFileData: function(file) {
            if(!file){
                return
            }

            var reader = new FileReader()
            reader.readAsDataURL(file)
            reader.addEventListener('load', (e) => {
                this.object.file = e.target.result
            })

        }
	}
}

</script>