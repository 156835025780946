<template>
	<v-dialog persistent max-width="764px" ref="createDialog" v-model="dialog">
		<v-form lazy-validation ref="form" v-model="valid">
			<v-card>
				<v-card-title>
					<span class="text-h5">Create Project Campaign</span>
				</v-card-title>
				<v-card-text>
					<v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="errors && errors.message">
                            <v-alert dense outlined type="error" class="mb-0"><span v-text="errors.message"></span></v-alert>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense label="Campaign Name*" :rules="rules.name" :error-messages="errors.name" v-model="object.name"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense persistent-hint label="Allow Distance (Meter)" hint="Default distance is 200 meters" :rules="rules.distance" :error-messages="errors.distance" v-model="object.allow_distance"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-dialog persistent width="320px" ref="startDialog" :return-value.sync="object.start_date" v-model="startDateDialog">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field readonly dense label="Start Date*" :rules="rules.start_date" :error-messages="errors.start_date" v-on="on" v-bind="attrs" v-model="object.start_date"></v-text-field>
                                </template>
                                <v-date-picker scrollable color="primary" v-model="object.start_date">
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="startDateDialog=false">Cancel</v-btn>
                                    <v-btn text color="primary" @click="$refs.startDialog.save(object.start_date)">OK</v-btn>
                                </v-date-picker>
                            </v-dialog>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-dialog persistent width="320px" ref="endDialog" :return-value.sync="object.end_date" v-model="endDateDialog">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field readonly dense label="End Date*" :rules="rules.end_date" :error-messages="errors.end_date" v-on="on" v-bind="attrs" v-model="object.end_date"></v-text-field>
                                </template>
                                <v-date-picker scrollable color="primary" v-model="object.end_date">
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="endDateDialog=false">Cancel</v-btn>
                                    <v-btn text color="primary" @click="$refs.endDialog.save(object.end_date)">OK</v-btn>
                                </v-date-picker>
                            </v-dialog>
						</v-col>
					</v-row>
					<v-row v-if="skus.length > 0">
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <p class="text-subtitle-1 font-weight-regular mb-0 black--text">Campaign SKU</p>
                            <v-simple-table dense>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <td class="mx-auto" width="80px"></td>
                                            <td class="text-center">SKU Image</td>
                                            <td class="text-center">SKU Name</td>
                                            <td class="text-center" width="200px">Promotion Price (S$)</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr :key="index" v-for="(item, index) in skus">
                                            <td class="mx-auto"><v-checkbox class="mx-auto" v-model="item.checked"></v-checkbox></td>
                                            <td><v-img contain class="mx-auto" width="40" :src="item.thumbnail_url"></v-img></td>
                                            <td class="text-center" v-text="item.name"></td>
                                            <td class="text-center">
                                                <span v-text="item.price" v-if="!item.checked"></span>
                                                <v-text-field placeholder="New Promotion Price" class="field-text-center" :rules="rules.price" v-model="item.price" v-if="item.checked"></v-text-field>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
					<v-row v-if="false">
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <p class="text-subtitle-1 font-weight-regular mb-0 black--text">Compulsory Task</p>
                        </v-col>
                        <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                            <v-checkbox dense label="Take Photo" v-model="object.is_photo"></v-checkbox>
                        </v-col>
                        <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">
                            <v-checkbox dense label="Submit Sales" v-model="object.is_sale"></v-checkbox>
                        </v-col>
					</v-row>
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <small class="red--text">* Required field</small>
                        </v-col>
                    </v-row>
				</v-card-text>
				<v-card-actions class="pl-4 pr-4">
					<v-spacer></v-spacer>
					<v-btn text @click="close">Close</v-btn>
					<v-btn text color="primary" :loading="loading" @click="create">Create</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>


<script>

import { copyObject } from '@/utils/helper'
import { stringifyJSON } from '@/utils/encodes'

export default {
	name: 'ProjectCampaignCreateDialog',
	props: {
        skus: {
            type: Array,
            required: false,
            default: () => []
        }
    },
	data() {
		return {
			dialog: false,
			valid: true,
			loading: false,
			startDateDialog: false,
			endDateDialog: false,
			object: {
                project: this.$route.params.id,
                name: '',
                start_date: '',
                end_date:'',
                allow_distance: 200,
                is_photo: false,
                is_sale: false,
                skus: {}
			},
			rules: {
                name: [
                    (value) => !!value || 'Campaign name is required',
                    (value) => value && value.length >= 2 && value.length <= 100 || 'Campaign name must be between 6 and 100 characters'
                ],
                price: [
                    (value) => {
                        if(value && isNaN(value)){
                            return "Promotion price must be a numeric value"
                        }

                        return true
                    }
                ],
                distance: [
                    (value) => {
                        if(value && isNaN(value)) {
                            return "Allow distance must be numeric value"
                        }

                        return true
                    }
                ],
                start_date: [
                    (value) => !!value || 'Start date is required',
                ],
                end_date: [
                    (value) => {
                        if(!value) {
                            return "End date is required"
                        }

                        if(this.object.start_date){
                            var startDate = new Date(this.object.start_date)
                            var endDate = new Date(value)

                            if(startDate > endDate){
                                return "End date cannot greater than the start date"
                            }

                            return true
                        }

                    }
                ]
			},
			errors: {}
		}
	},
	computed: {
		createDialog() {
			return this.$refs.createDialog
		},
		form() {
			return this.$refs.form
		}
	},
	methods: {
		open: function() {
			this.dialog = true
		},
		close: function() {
			this.dialog = false
			this.loading = false
            this.object = { project: this.$route.params.id, name: '', start_date: '', end_date: '', allow_distance: '',
                            is_photo: '', is_sale: '', skus: {} }
			this.errors = {}
			this.form.reset()
			this.form.resetValidation()
		},
		create: function() {
			if(this.form.validate()){
				this.loading = true
                const object = copyObject(this.object, ['skus'])
                object.skus = stringifyJSON(this.filterCheckedSKUs())

				this.$store.dispatch('epanel/project/createProjectCampaign', object).then((response) => {
                    this.close()
                    this.$emit('created', { message: response.message })
                }).catch((errors) => {
                    this.errors = errors.data?errors.data.errors:errors
                    this.loading = false
                })
			}
		},
        filterCheckedSKUs: function() {
            var checkedItems = {}
            this.skus.forEach((item) => {
                if(item.checked){
                    checkedItems[item.id] = item.price
                }
            })
            return checkedItems
        }
	}
}

</script>


<style scoped>
/deep/ .v-list-item {
    padding: 0px !important;
}

</style>