<template>
	<div>
		<v-row>
			<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
				<v-sheet color="white" elevation="0" class="pa-0 py-4">
					<p class="text-h6 mb-4">Search</p>
					<v-row>
                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
							<v-text-field dense clearable hide-details label="SKU Name" v-model="filters.name"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" order="last">
							<v-btn small color="primary" class="mr-2 elevation-0" :ripple="false" @click='search'>
								<v-icon small left>mdi-magnify</v-icon> Search
							</v-btn>
							<v-btn small class="elevation-0" :ripple="false" @click='reset'>
								<v-icon small left>mdi-undo</v-icon> Reset
							</v-btn>
						</v-col>
					</v-row>
				</v-sheet>
			</v-col>
		</v-row>
		<v-row v-if="permissions.can_add">
			<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="addable">
				<v-btn small color="primary" class="elevation-0" :ripple="false" @click='openCreateDialog'>
					<v-icon small left>mdi-plus</v-icon> Create SKU
				</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
				<v-data-table hide-default-footer class="elevation-0" loading-text="Loading... Please wait..." no-data-text="No data available yet" :loading="loading" :items-per-page="50" :headers="headers" :items="items">
					<template v-slot:item='{ item, index }'>
						<tr>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="index + 1"></td>
							<td>
                                <v-img width="100" class="mx-auto" :src="item.image_url"></v-img>
							</td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.name"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.price"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.created_on"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.updated_on"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
								<v-menu bottom offset-y v-if="hasMenu(item)">
									<template v-slot:activator="{ on, attrs }">
										<v-btn small icon v-bind="attrs" v-on="on">
											<v-icon>mdi-dots-horizontal</v-icon>
										</v-btn>
									</template>
									<v-list dense>
										<v-list-item @click="openUpdateDialog(item, index)" v-if="item.can_edit">
											<v-list-item-title>Update SKU</v-list-item-title>
										</v-list-item>
										<v-list-item @click="openDeleteDialog(item)" v-if="item.can_delete">
											<v-list-item-title>Delete SKU</v-list-item-title>
										</v-list-item>
									</v-list>
								</v-menu>
							</td>
						</tr>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		<v-row v-if="!loading && pagination.total_pages > 1">
			<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
				<v-pagination circle :length="pagination.total_pages" :total-visible="7" v-model="filters.page" @input="get"></v-pagination>
			</v-col>
		</v-row>
		<create-dialog ref="createDialog" @created="updateItems"></create-dialog>
		<update-dialog ref="updateDialog" @updated="updateItem"></update-dialog>
		<delete-dialog ref="deleteDialog" @deleted="updateItems"></delete-dialog>
		<message-notifier ref="notifier"></message-notifier>
	</div>
</template>


<script>

import MessageNotifier from '../../../../shared/MessageNotifier.vue'
import ProjectSKUCreateDialog from './ProjectSKUCreateDialog.vue'
import ProjectSKUUpdateDialog from './ProjectSKUUpdateDialog.vue'
import ProjectSKUDeleteDialog from './ProjectSKUDeleteDialog.vue'

export default {
	name: 'ProjectSKUList',
    props: {
        addable: {
            type: Boolean,
            required: false,
            default: false
        },
    },
	components: {
        messageNotifier: MessageNotifier,
        createDialog: ProjectSKUCreateDialog,
		updateDialog: ProjectSKUUpdateDialog,
		deleteDialog: ProjectSKUDeleteDialog,
	},
	data () {
		return {
			loading: false,
			selectedIndex: -1,
			filters: {
				project: this.$route.params.id,
				name: '',
				type: 'project',
				page: 1
			},
            permissions: {
                can_list: false,
                can_add: false
            },
			items: [],
			headers: [],
			pagination: {}
		}
	},
	computed: {
		createDialog() {
			return this.$refs.createDialog
		},
		updateDialog() {
			return this.$refs.updateDialog
		},
        deleteDialog() {
            return this.$refs.deleteDialog
        },
		notifier() {
            return this.$refs.notifier
        },
        dialog() {
            return this.$refs.dialog
        }
	},
	mounted: function() {
		this.get()
	},
	methods: {
		get: function() {
			this.loading = true
			this.items = []

			this.$store.dispatch('epanel/project/getProjectSKUs', this.filters).then((response) => {
				this.items = response.data.items
				this.headers = response.data.headers
				this.pagination = response.data.pagination
				this.permissions = response.data.permissions
				this.loading = false
			}).catch(() => {
				this.loading = false
			})
		},
		search: function() {
			this.loading = true
			this.filters.page = 1
			this.get()
		},
		reset: function() {
			this.filters = { project: this.$route.params.id, name: '', page: 1}
		},
        openCreateDialog: function() {
            if(this.permissions.can_add){
                this.createDialog.open()
            }
        },
        openUpdateDialog: function(item, index) {
            if(item.can_edit){
                this.selectedIndex = index
                this.updateDialog.updateObject({ id: item.id, name: item.name, price: item.price })
                this.updateDialog.open()
            }
        },
        openDeleteDialog: function(item) {
            if(item.can_delete){
                this.deleteDialog.updateObject({ id: item.id })
                this.deleteDialog.open()
            }
        },
		openNotifier: function(message) {
            this.notifier.updateText(message)
            this.notifier.open()
        },
        updateItems: function(item) {
            this.reset()
            this.get()
            this.openNotifier(item.message)
		},
		updateItem: function(item) {
            this.$set(this.items, this.selectedIndex, item.object)
            this.openNotifier(item.message)
		},
        hasMenu: function(item) {
            return item.can_edit || item.can_delete
        }
	},
	watch: {
        "addable": function(){
            this.get()
        }
    }
}

</script>