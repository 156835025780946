<template>
	<v-dialog persistent max-width="764px" ref="createDialog" v-model="dialog">
		<v-form lazy-validation ref="form" v-model="valid">
			<v-card>
				<v-card-title class="pl-4 pr-4">
					<span class="text-h5">Create Project Resource</span>
				</v-card-title>
				<v-card-text class="pl-4 pr-4">
					<v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="errors && errors.message">
                            <v-alert dense outlined type="error" class="mb-0"><span v-text="errors.message"></span></v-alert>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense label="File Name*" :rules="rules.name" :error-messages="errors.name" v-model="object.name"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense label="Days of Valid*" :rules="rules.expiry_days" :error-messages="errors.expiry_days" v-model="object.expiry_days"></v-text-field>
						</v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-file-input ref="file" label="PDF File*" show-size accept="application/pdf" :rules="rules.file" :error-messages="errors.file" @change="readFileData"></v-file-input>
                            <small class="red--text">Note: Only accepts PDF file (Less than 5 MB)</small>
                        </v-col>
					</v-row>
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <small class="red--text">* Required field</small><br>
                            <small class="red--text">Note: Days of valid indicates the file will be deleted after the number of days of expiry. Leave '0' as permanent.</small>
                        </v-col>
                    </v-row>
				</v-card-text>
				<v-card-actions class="pl-4 pr-4">
					<v-spacer></v-spacer>
					<v-btn text @click="close">Close</v-btn>
					<v-btn text color="primary" :loading="loading" @click="create">Upload</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>


<script>

import { copyObject } from '@/utils/helper'

export default {
	name: 'ProjectResourceCreateDialog',
	data() {
		return {
			dialog: false,
			valid: true,
			loading: false,
			object: {
                project: this.$route.params.id,
                source: 'brand',
                name: '',
                file: '',
                expiry_days: 90,
			},
			rules: {
                project: [
                    (value) => !!value || 'Project is required'
                ],
                name: [
                    (value) => !!value || 'File name is required',
                    (value) => value && value.length >= 2 && value.length <= 100 || 'Name must be between 6 and 100 characters'
                ],
                file: [
                    (value) => {
                        if(!value) {
                            return 'PDF File is required'
                        }

                        var fileSize = Math.ceil(value.size / 1024)

                        if(fileSize > 5120) {
                            return 'PDF File must less than 5MB'
                        }

                        return true
                    }
                ],
                expiry_days: [
                    (value) => {
                        if(!value) {
                            return "Days of valid is required"
                        }

                        if(!/^\d+$/.test(value)){
                            return 'Days of valid must be numeric value'
                        }

                        return true
                    }
                ]
			},
			errors: {}
		}
	},
	computed: {
		createDialog() {
			return this.$refs.createDialog
		},
		form() {
			return this.$refs.form
		}
	},
	methods: {
		open: function() {
			this.dialog = true
		},
		close: function() {
			this.dialog = false
			this.loading = false
			this.form.resetValidation()
			this.object = { project: this.$route.params.id, name: '', file: '', expiry_days: 90, source: 'brand' }
			this.errors = {}

		},
		create: function() {
			if(this.form.validate()){
				this.loading = true
                const object = copyObject(this.object)

				this.$store.dispatch('epanel/project/createProjectResource', object).then((response) => {
                    this.close()
                    this.$emit('created', { message: response.message })
                }).catch((errors) => {
                    this.errors = errors.data?errors.data.errors:errors
                    this.loading = false
                })
			}
		},
        readFileData: function(file) {
            if(!file){
                return
            }

            var reader = new FileReader()
            reader.readAsDataURL(file)
            reader.addEventListener('load', (e) => {
                this.object.file = e.target.result
            })

        }
	}
}

</script>