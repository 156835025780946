<template>
    <v-dialog persistent max-width="764px" v-model="dialog">
        <v-card>
            <v-card-title class="pl-4 pr-4">
                <span class="text-h5">Archive Brand</span>
            </v-card-title>
            <v-card-text class="pl-4 pr-4">
                <v-row>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pb-2" v-if="errors && errors.message">
                        <v-alert dense outlined type="error" class="mb-0"><span v-text="errors.message"></span></v-alert>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <v-alert dense class="text-subtitle-1" type="warning">
                            <span v-if="isArchive()">Are you sure that you want to archive this brand? Once you archive this brand, you only can view this brand and its relevant data.</span>
                            <span v-if="!isArchive()">Are you sure that you want to activate this brand? Once you activate this brand, you can manage this brand and its relevant data.</span>
                        </v-alert>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="text-right pr-6 pl-6">
                <v-spacer></v-spacer>
                <v-btn text @click="close">Cancel</v-btn>
                <v-btn text color="primary" :loading="loading" @click="save" v-text="getConfirmText()"></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>

export default {
    name: 'ProjectArchiveDialog',
    data() {
        return {
            dialog: false,
            loading: false,
            object: { status: '' },
            errors: {}
        }
    },
    methods: {
        open: function() {
            this.dialog = true
        },
        close: function() {
            this.dialog = false
            this.loading = false
            this.errors = {}
        },
        updateObject: function(item) {
            this.object = item
        },
        save: function() {
            this.loading = true
            this.$store.dispatch('epanel/project/archiveProject', { id: this.object.id, object: {status: this.object.status} }).then((response) => {
                this.$emit('archived', { object: response.data.object, message: response.message })
                this.close()
            }).catch((errors) => {
                this.errors = errors.data?errors.data.errors:errors
                this.loading = false
            })
        },
        isArchive: function() {
            return this.object.status === 'AR'?true:false
        },
        getConfirmText: function() {
            return this.object.status === 'AR'?'Archive':'Activate'
        }
    }
}

</script>