<template>
	<div>
		<v-row>
			<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
				<v-sheet color="white" elevation="0" class="pa-0 py-4">
					<p class="text-h6 mb-4">Search</p>
					<v-row>
                        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
							<v-text-field dense clearable hide-details label="Name" v-model="filters.name"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <v-dialog persistent width="320px" ref="startDialog" :return-value.sync="filters.start_date" v-model="startDateDialog">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field readonly dense hide-details label="Start Date" v-on="on" v-bind="attrs" v-model="filters.start_date"></v-text-field>
                                </template>
                                <v-date-picker scrollable v-model="filters.start_date">
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="startDateDialog=false">Cancel</v-btn>
                                    <v-btn text color="primary" @click="$refs.startDialog.save(filters.start_date)">OK</v-btn>
                                </v-date-picker>
                            </v-dialog>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <v-dialog persistent width="320px" ref="endDialog" :return-value.sync="filters.end_date" v-model="endDateDialog">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field readonly dense hide-details label="End Date" v-on="on" v-bind="attrs" v-model="filters.end_date"></v-text-field>
                                </template>
                                <v-date-picker scrollable v-model="filters.end_date">
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="endDateDialog=false">Cancel</v-btn>
                                    <v-btn text color="primary" @click="$refs.endDialog.save(filters.end_date)">OK</v-btn>
                                </v-date-picker>
                            </v-dialog>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" order="last">
							<v-btn small color="primary" class="mr-2 elevation-0" :ripple="false" @click='search'>
								<v-icon small left>mdi-magnify</v-icon> Search
							</v-btn>
							<v-btn small class="elevation-0" :ripple="false" @click='reset'>
								<v-icon small left>mdi-undo</v-icon> Reset
							</v-btn>
						</v-col>
					</v-row>
				</v-sheet>
			</v-col>
		</v-row>
		<v-row v-if="permissions.can_add">
			<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="addable">
				<v-btn small color="primary" class="elevation-0" :ripple="false" @click='openCreateDialog'>
					<v-icon small left>mdi-plus</v-icon> Create Campaign
				</v-btn>
			</v-col>
		</v-row>
		<v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
				<v-data-table hide-default-footer class="elevation-0" loading-text="Loading... Please wait..." no-data-text="No data available yet" :loading="loading" :items-per-page="50" :headers="headers" :items="items">
					<template v-slot:item='{ item, index }'>
						<tr>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="index + 1"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.name"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.start_date.text"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.end_date.text"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.updated_on"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
                                <v-chip label small :color="item.status.color" class="mr-2"><span v-text="item.status.text"></span></v-chip>
                            </td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
								<v-menu bottom offset-y v-if="hasMenu(item)">
									<template v-slot:activator="{ on, attrs }">
										<v-btn small icon v-bind="attrs" v-on="on">
											<v-icon>mdi-dots-horizontal</v-icon>
										</v-btn>
									</template>
									<v-list dense>
										<v-list-item @click="openUpdateDialog(item, index)" v-if="item.can_edit">
											<v-list-item-title>Update Campaign</v-list-item-title>
										</v-list-item>
										<v-list-item :to="getDeploymentUrl(item.id)" target="_blank" v-if="item.can_deploy">
											<v-list-item-title>Deploy Campaign Shift</v-list-item-title>
										</v-list-item>
										<v-list-item @click="openDeleteDialog(item)" v-if="item.can_delete">
											<v-list-item-title>Delete Campaign</v-list-item-title>
										</v-list-item>
										<v-list-item :to="getTimesheetUrl(item.id)" target="_blank" v-if="item.can_view">
											<v-list-item-title>View Campaign Shifts</v-list-item-title>
										</v-list-item>
									</v-list>
								</v-menu>
							</td>
						</tr>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		<v-row v-if="!loading && pagination.total_pages > 1">
			<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
				<v-pagination circle :length="pagination.total_pages" :total-visible="7" v-model="filters.page" @input="get"></v-pagination>
			</v-col>
		</v-row>
		<create-dialog ref="createDialog" :skus="skus" @created="updateItems"></create-dialog>
		<update-dialog ref="updateDialog" :skus="skus" @updated="updateItem" @clearSkus="resetSkus"></update-dialog>
		<delete-dialog ref="deleteDialog" @deleted="updateItems"></delete-dialog>
		<message-notifier ref="notifier"></message-notifier>
	</div>
</template>


<script>

import { copyObject } from '@/utils/helper'
import MessageNotifier from '../../../../shared/MessageNotifier.vue'
import ProjectCampaignCreateDialog from './ProjectCampaignCreateDialog.vue'
import ProjectCampaignUpdateDialog from './ProjectCampaignUpdateDialog.vue'
import ProjectCampaignDeleteDialog from './ProjectCampaignDeleteDialog.vue'

export default {
	name: 'ProjectCampaignList',
    props: {
        addable: {
            type: Boolean,
            required: false,
            default: false
        },
    },
	components: {
        messageNotifier: MessageNotifier,
        createDialog: ProjectCampaignCreateDialog,
        updateDialog: ProjectCampaignUpdateDialog,
        deleteDialog: ProjectCampaignDeleteDialog,
	},
	data () {
		return {
			loading: false,
			selectedIndex: -1,
			startDateDialog: false,
			endDateDialog: false,
			filters: {
				project: this.$route.params.id,
				name: '',
				start_date: '',
				end_date: '',
				page: 1
			},
            permissions: {
                can_list: false,
                can_add: false
            },
            headers: [],
			items: [],
			skus: [],
			pagination: {}
		}
	},
	computed: {
		createDialog() {
			return this.$refs.createDialog
		},
		updateDialog() {
			return this.$refs.updateDialog
		},
        deleteDialog() {
            return this.$refs.deleteDialog
        },
		notifier() {
            return this.$refs.notifier
        },
        dialog() {
            return this.$refs.dialog
        }
	},
	mounted: function() {
		this.get()
	},
	methods: {
		get: function() {
			this.loading = true
			this.items = []
            this.$store.dispatch('epanel/project/getProjectCampaigns', this.filters).then((response) => {
                this.headers = response.data.headers
				this.items = response.data.items
				this.skus = response.data.skus
				this.pagination = response.data.pagination
				this.permissions = response.data.permissions
				this.loading = false
			}).catch(() => {
				this.loading = false
			})
		},
		search: function() {
			this.loading = true
			this.filters.page = 1
			this.get()
		},
		reset: function() {
			this.filters = { project: this.$route.params.id, name: '', start_date: '', end_date: '', page: 1}
		},
        openCreateDialog: function() {
            if(this.permissions.can_add){
                this.createDialog.open()
            }
        },
        openUpdateDialog: function(item, index) {
            if(item.can_edit){
                this.selectedIndex = index
                var object = copyObject(item, ['start_date', 'end_date', 'updated_on', 'skus'])
                object.start_date = item.start_date.value
                object.end_date = item.end_date.value
                this.updateDialog.updateObject(object)
                this.updateDialog.updateSKUs(this.formattedSKU(item.skus))
                this.updateDialog.open()
            }
        },
        openDeleteDialog: function(item) {
            if(item.can_delete){
                this.deleteDialog.updateObject({ id: item.id })
                this.deleteDialog.open()
            }
        },
		openNotifier: function(message) {
            this.notifier.updateText(message)
            this.notifier.open()
        },
        updateItems: function(item) {
            this.reset()
            this.get()
            this.openNotifier(item.message)
		},
		updateItem: function(item) {
            this.$set(this.items, this.selectedIndex, item.object)
            this.openNotifier(item.message)
		},
		getDeploymentUrl: function(id) {
            return '/epanel/project/brand/' + id + '/deployment'
		},
		getTimesheetUrl: function(id) {
            return '/epanel/project/brand/' + id + '/timesheet'
		},
        formattedSKU: function(skus){
            var items = {}, formattedSkus = []
            skus.forEach((item) => {
                items[item.id] = item.price
            })

            this.skus.forEach((item) => {
                if(item.id in items){
                    item.checked = true
                    item.price = items[item.id]
                }
                formattedSkus.push(item)
            })

            return formattedSkus
        },
        hasMenu: function(item) {
            return item.can_edit || item.can_deploy || item.can_delete || item.can_view
        },
		resetSkus: function() {
			this.skus = [];
			this.get();
		}
	},
    watch: {
        "addable": function(){
            this.get()
        }
    }
}

</script>